.c-LoginMask {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--primary-blue);
    background-image: url("./background.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.c-LoginMask-card {
    width: 360px;
}

.c-LoginMask-card-header {
    margin-bottom: 48px;
    text-align: center;
}

.c-LoginMask-card-title {
    color: var(--grey-9);
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
}

.c-LoginMask-card-header .c-Logo {
    width: 150px;
}

.c-LoginMask-login-button {
    width: 100%;
}

.c-LoginMask-JwtLoginForm-text-field {
    margin-bottom: 4px;
}
.c-LoginMask-JwtLoginForm-text-field .anticon {
    font-size: 13px;
}
