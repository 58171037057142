/* Remove horizontal marign from table */
.c-BundlesList .ant-table-body {
    margin: 0px !important;
}
/* Remove table border and border radius */
.c-BundlesList .ant-table-small {
    border: 0px;
    border-radius: 0px;
}
.c-BundlesList .ant-table-thead > tr:first-child > th:first-child,
.c-BundlesList .ant-table-thead > tr:first-child > th:last-child {
    border-radius: 0px;
}

/* Remove the table header background color */
.c-BundlesList .ant-table-thead th {
    background-color: transparent !important;
}

/* Highlight rows of custom color on hover */
.c-BundlesList-row:hover td {
    background: var(--blue-1) !important;
}

/* Remove row background-change-on-hover delay */
.c-BundlesList .ant-table-thead > tr,
.c-BundlesList .ant-table-tbody > tr,
.c-BundlesList .ant-table-tbody > tr > td {
    transition: all 0s !important;
}

/* Remove expanded row background */
.c-BundlesList tr.ant-table-expanded-row td,
.c-BundlesList tr.ant-table-expanded-row:hover td {
    background: transparent !important;
}

/* Give space below */
.c-BundlesList {
    margin-bottom: 16px;
}

/* Set table and table columns widths */
.c-BundlesList table {
    width: 100%;
    table-layout: fixed !important;
}
.c-BundlesList-id-column {
    width: 100px;
}
.c-BundlesList-description-column {
    width: 100%;
}
.c-BundlesList-createdAt-column {
    width: 150px;
}
