.c-DataFetcher-spinner {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    min-height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
