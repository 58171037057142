/* Remove horizontal marign from table */
.c-OperationLogsList .ant-table-body {
    margin: 0px !important;
}
/* Remove table border and border radius */
.c-OperationLogsList .ant-table-small {
    border: 0px;
    border-radius: 0px;
}
.c-OperationLogsList .ant-table-thead > tr:first-child > th:first-child,
.c-OperationLogsList .ant-table-thead > tr:first-child > th:last-child {
    border-radius: 0px;
}

/* Remove the table header background color */
.c-OperationLogsList .ant-table-thead th {
    background-color: transparent !important;
}

/* Highlight rows of custom color on hover */
.c-OperationLogsList-row:hover td {
    background: var(--blue-1) !important;
}

/* Remove row background-change-on-hover delay */
.c-OperationLogsList .ant-table-thead > tr,
.c-OperationLogsList .ant-table-tbody > tr,
.c-OperationLogsList .ant-table-tbody > tr > td {
    transition: all 0s !important;
}

/* Remove expanded row background */
.c-OperationLogsList tr.ant-table-expanded-row td,
.c-OperationLogsList tr.ant-table-expanded-row:hover td {
    background: transparent;
}

/* Give space below */
.c-OperationLogsList {
    margin-bottom: 16px;
}

.c-OperationLogsList-operation-cell .ant-tag {
    /* Make all tags the same width */
    width: 180px;
    /* Make tag text uppercase, not selectable */
    text-transform: uppercase;
    user-select: none;
}
/* Color tags differently depending on operation */
.c-OperationLogsList-operation-cell .apps-create,
.c-OperationLogsList-operation-cell .bundles-create,
.c-OperationLogsList-operation-cell .entrypoints-create,
.c-OperationLogsList-operation-cell .groups-create,
.c-OperationLogsList-operation-cell .users-create {
    color: var(--green-6);
    background: var(--green-1);
    border-color: var(--green-3);
}
.c-OperationLogsList-operation-cell .apps-update,
.c-OperationLogsList-operation-cell .entrypoints-update,
.c-OperationLogsList-operation-cell .groups-update,
.c-OperationLogsList-operation-cell .users-update {
    color: var(--blue-6);
    background: var(--blue-1);
    border-color: var(--blue-3);
}
.c-OperationLogsList-operation-cell .apps-delete,
.c-OperationLogsList-operation-cell .bundles-delete,
.c-OperationLogsList-operation-cell .entrypoints-delete,
.c-OperationLogsList-operation-cell .groups-delete,
.c-OperationLogsList-operation-cell .users-delete {
    color: var(--red-6);
    background: var(--red-1);
    border-color: var(--red-3);
}

/* Add padding to details json viewer, round its corners */
.c-OperationLogsList-details > ul {
    padding: 16px !important;
    border-radius: 4px;
}

/* Hide the no-data placeholder */
.c-OperationLogsList .ant-table-placeholder {
    display: none;
}
